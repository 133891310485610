<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="dismissModal()">Kapat</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div class="site-content">
      <div class="checkout-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="billing-form" style="margin: 50px;">
                <form @submit="sendForm">
                  <div class="field-group">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="field-input">
                          <ion-label for="title">Adı <span class="required">*</span></ion-label>
                          <ion-input type="text" :value="searchNotification?.name" name="title" id="title" required/>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="field-input">
                          <ion-label for="type">İlan Tipi <span class="required">*</span></ion-label>
                          <ion-select interface="action-sheet" id="type" name="type" :value="getOption('Type').value ?? '*'">
                            <ion-select-option value="*">
                              Hepsi
                            </ion-select-option>
                            <ion-select-option value="0">
                              Alış
                            </ion-select-option>
                            <ion-select-option value="1">
                              Satış
                            </ion-select-option>
                          </ion-select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="field-input">
                          <ion-label for="category">Kategori <span class="required">*</span></ion-label>
                          <ion-select id="category" interface="alert" cancel-text="İptal" ok-text="Onayla" name="category" :value="getOption('Category').value !== '*' ? parseInt(getOption('Category').value) : '*'">
                            <ion-select-option value="*">
                              Hepsi
                            </ion-select-option>
                            <ion-select-option v-for="category in this.$api.categories" :key="category.id" :value="category.id">
                              {{ category.name }}
                            </ion-select-option>
                          </ion-select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="field-input">
                          <ion-label for="city">Şehir <span class="required">*</span></ion-label>
                          <ion-select id="city" interface="alert" cancel-text="İptal" ok-text="Onayla" name="city" :value="getOption('City').value !== '*' ? parseInt(getOption('City').value) : '*'">
                            <ion-select-option value="*">
                              Hepsi
                            </ion-select-option>
                            <ion-select-option v-for="city in this.$api.cities" :key="city.id" :value="city.id">
                              {{ city.name }}
                            </ion-select-option>
                          </ion-select>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <ion-label for="city">Fiyat Tipi <span class="required">*</span></ion-label>
                        <ion-select id="price_type" interface="alert" cancel-text="İptal" ok-text="Onayla" name="price_type" v-model="selectedPriceType" :value="getOption('PriceType').value !== '*' ? parseInt(getOption('PriceType').value) : '*'">
                          <ion-select-option value="*">
                            Hepsi
                          </ion-select-option>
                          <ion-select-option v-for="priceType in this.$api.priceTypes" :key="priceType.id" :value="priceType.id">
                            {{ priceType.name }}
                          </ion-select-option>
                        </ion-select>
                      </div>
                      <template v-if="selectedPriceType !== '*'">
                        <div class="col-md-6">
                          <div class="field-input">
                            <ion-label for="value">Fiyat Başlangıç <span class="required">*</span></ion-label>
                            <ion-input type="text" :value="getOption('PriceType').value_min" name="value" id="value" required/>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="field-input">
                            <ion-label for="value_top">Fiyat Bitiş<span class="required">*</span></ion-label>
                            <ion-input type="text" :value="getOption('PriceType').value_top" name="value_top" id="value_top"
                                       required/>
                          </div>
                        </div>
                      </template>
                      <div class="col-md-6">
                        <div class="field-input">
                          <ion-button type="submit" name="submit">
                            Kaydet
                          </ion-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script>
import {
  IonButton, IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from '@ionic/vue';
import {defineComponent} from 'vue';
import {ref} from "@vue/runtime-core";

export default defineComponent({
  name: 'SearchNotificationModal',
  props: {
    title: {type: String, default: ''},
    searchNotification: {
      type: Object
    },
    isNew: {type: Boolean, default: true},
    id: {
      type: Number
    }
  },
  setup(){
    const selectedPriceType = ref('*');
    return {selectedPriceType};
  },
  mounted() {
    this.selectedPriceType = this.getOption('PriceType').value === '*' ? '*' : parseInt(this.getOption('PriceType').value);
  },
  // eslint-disable-next-line vue/no-unused-components
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonInput,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonButtons
  },
  methods: {
    getOption: function (optionName) {
      let val = Object.keys(this.searchNotification.search_notification_options).filter((key) =>
          this.searchNotification.search_notification_options[key]?.type === optionName)[0];
      if (val){
        return this.searchNotification.search_notification_options[val];
      }
      return {value: '0',value_top: '0'};
    },
    dismissModal: function () {
      this.$emitter.emit('closeSearchNotificationModal');
    },
    sendForm: function (event) {
      event.preventDefault();
      const formData = new FormData(event.target);
      const data = {
        name: formData.get('title'),
        search_notification_options: [
          {
            type: "City",
            value: formData.get('city'),
            value_top: '0'
          },
          {
            type: "PriceType",
            value: formData.get('price_type'),
            value_min: formData.get('value') ?? '0',
            value_top: formData.get('value_top') ?? '0',
          },
          {
            type: "Category",
            value: formData.get('category'),
            value_top: '0'
          },
          {
            type: "Type",
            value: formData.get('type'),
            value_top: '0'
          },
        ]
      };
      let _this = this;
      if (this.isNew) {
        return this.$api.post('/search-notifications', data)
            .then(() => {
              _this.$emitter.emit('updateProfile', _this.$api.getAuth());
              this.$emitter.emit('closeSearchNotificationModal');
            });
      }
      this.$api.put('/search-notifications/' + this.id, data)
          .then(() => {
            _this.$emitter.emit('updateProfile', _this.$api.getAuth());
            this.$emitter.emit('closeSearchNotificationModal');
          });

    }
  }
});
</script>