<template>
  <ion-page>
    <ion-content>
      <paid-component/>
      <div class="tab-buttons container">
        <div class="row" style="background-color: white;">
          <div class="col-6">
            <ion-button @click="changeTab('notifications')"
                        :color="selectedTab === 'notifications' ? 'dark': 'light'"
                        :class="{ 'tab-button-active': selectedTab === 'notifications' }" style="width: 100%;">
              <ion-icon :icon="selectedTab === 'notifications' ? notificationsIcon() : notificationsOutline()"
                        color="success">
              </ion-icon>
              <ion-label>Bildirimler</ion-label>
            </ion-button>
          </div>
          <div class="col-6">
            <ion-button @click="changeTab('settings')" :class="{ 'tab-button-active': selectedTab === 'settings' }"
                        :color="selectedTab === 'settings' ? 'dark': 'light'"
                        style="width: 100%;">
              <ion-icon :icon="selectedTab === 'settings' ? settings() : settingsOutline()" color="success"></ion-icon>
              <ion-label>Ayarlar</ion-label>
            </ion-button>
          </div>
        </div>
      </div>
      <main id="main" class="sitemain">
        <div class="page-title page-title--small align-left" style="background-color: #53bd6c!important;">
          <div class="container">
            <div class="page-title__content">
              <h1 class="page-title__name">Bildirimler & Ayarları</h1>
              <p class="page-title__slogan">Burada bildirimlerinizi görebilir ve düzenleyebilirsiniz.</p>
            </div>
          </div>
        </div>
      </main>
        <div>
          <Transition  mode="out-in">
          <div v-if="selectedTab === 'notifications'" class="site-content">
            <div class="checkout-area">
              <div class="container">
                <div class="row">
                  <div class="col-lg-3"></div>
                  <div class="col-12 col-lg-6">
                    <div class="billing-form" style="    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;">
                      <div>
                        <table style="width: 100%;">
                          <thead>
                          </thead>
                          <tbody>
                          <tr v-for="(notification, index) in notifications" :key="index">
                            <td :style="notification.read_at ? '' : 'color: green; font-weight:bolder'">
                              {{ notification.data.message }}
                            </td>
                            <td>
                              <ion-button @click="showNotification(notification)" class="white" color="light" shape="round">
                                <ion-icon slot="icon-only" :icon="eyeOutline()" color="dark"></ion-icon>
                              </ion-button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                      <ion-button @click="loadSeenNotifications()" class="white" color="light" shape="round"
                                  :disabled="nothingLeft">
                        {{ nothingLeft ? 'En alta indin' : 'Daha fazla' }}
                        <ion-icon slot="icon-only" :icon="eyeOutline()" color="dark"></ion-icon>
                      </ion-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <div v-else class="site-content">
              <div class="checkout-area">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-3"></div>
                    <div class="col-12 col-lg-6">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="billing-form">
                            <h2>Bildirim Tercihleri</h2>
                            <div class="row" v-for="(notificationOption, index) in notificationOptions" :key="index">
                              <div class="col-lg-12">
                                <div class="row">
                                  <div class="col-4">
                                    {{ keyConverter(notificationOption.key) }}
                                  </div>
                                  <div class="col-4">
                                    <div
                                        :style="{'fontWeight':'bolder','color':(notificationOption.value === true ? 'green': 'red')}">
                                      {{ notificationOption.value === true ? 'Gönderim Açık' : 'Gönderim Kapalı' }}
                                    </div>
                                  </div>
                                  <div class="col-4">
                                    <ion-button @click="changeNotification(notificationOption.token,notificationOption.key)"
                                                class="white" color="light" shape="round">
                                      <ion-icon slot="icon-only" :icon="createOutline()" color="dark"></ion-icon>
                                    </ion-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="billing-form">
                            <h2>Arama Bildirimleri</h2>
                            <div class="row">
                              <div class="col-8">
                              </div>
                              <div class="col-4">
                                <ion-button @click="addNewSearchNotification()">Yeni Ekle</ion-button>
                              </div>
                            </div>
                            <div class="row" v-if="searchNotifications.length !== 0">
                              <div class="col-4 col-md-4" style="text-align: center;align-self: center;">
                                Adı
                              </div>
                              <div class="col-8 col-md-8" style="text-align: center;align-self: center;">
                                Düzenle/Kapat veya Aç/Sil
                              </div>
                            </div>
                            <div class="row" v-for="(searchNotification, index) in searchNotifications" :key="index">
                              <div class="col-4 col-md-4" style="text-align: center;align-self: center;">
                                {{ searchNotification.name }}
                              </div>
                              <div class="col-8 col-md-8">
                                <div style="width: 100%;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;">
                                  <ion-button @click="editSearchNotification(searchNotification)" class="white" color="light"
                                              shape="round" size="small">
                                    <ion-icon slot="icon-only" :icon="createOutline()" color="dark"></ion-icon>
                                  </ion-button>
                                  <ion-button @click="toggleSearchNotification(searchNotification)" class="white" color="dark"
                                              shape="round" size="small">
                                    <div :style="searchNotification.is_enabled === true ? 'color: green' : 'color: red' ">
                                      {{ searchNotification.is_enabled === true ? 'Aktif' : 'Pasif' }}
                                    </div>
                                  </ion-button>
                                  <ion-button @click="deleteSearchNotification(searchNotification)" color="danger"
                                              shape="round" size="small">
                                    <ion-icon slot="icon-only" :icon="trashOutline()" color="dark">
                                    </ion-icon>
                                  </ion-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {defineComponent} from 'vue';
import {
  IonPage,
  IonButton,
  IonIcon,
  modalController,
  IonLabel, IonContent
} from "@ionic/vue";
import {
  createOutline,
  eyeOutline, notifications,
  notificationsOutline, settings,
  settingsOutline,
  trashOutline
} from "ionicons/icons";
import SearchNotificationModal from "../components/SearchNotificationModal.vue";
import PaidComponent from "../components/PaidComponent.vue";

export default defineComponent({
  name: 'Notification',
  components: {
    PaidComponent,
    IonIcon,
    IonPage, IonButton, IonLabel,
    IonContent
  },
  methods: {
    settings() {
      return settings
    },
    changeTab(tab) {
      this.selectedTab = tab;
    },
    settingsOutline() {
      return settingsOutline
    },
    notificationsIcon() {
      return notifications
    },
    notificationsOutline() {
      return notificationsOutline
    },
    trashOutline() {
      return trashOutline
    },
    eyeOutline() {
      return eyeOutline
    },
    createOutline() {
      return createOutline
    },
    showNotification: function (notification) {
      if (notification.type === 'App\\Notifications\\PostCreated') {
        if (notification.data_fresh.error){
          return this.$helper.setAlert('Hata', '',notification.data_fresh.error).then(alert => alert.present());
        }
        this.$router.push('/list/' + notification.data_fresh.slug);
      }
    },
    changeNotification: function (token, key) {
      let _this = this;
      this.$api.post('switch-notification', {token, key}).then(function (res) {
        _this.notificationOptions = res.data.user.notification_options;
      });
    },
    keyConverter: function (key) {
      switch (key) {
        case 'mail':
          return 'E-mail';
        case 'sms':
          return 'SMS';
        case 'broadcast':
          return 'Mobil Bildirim';
        default:
      }
    },
    addNewSearchNotification: async function () {
      const searchNotification = {
        'name': 'Arama İlanım',
        'search_notification_options': [
          {'type': 'City', 'value': '*', 'value_top': '0'},
          {'type': 'PriceType', 'value': '*','value_min': '0', 'value_top': '0'},
          {'type': 'Category', 'value': '*', 'value_top': '0'},
          {'type': 'Type', 'value': '*', 'value_top': '0'},
        ]
      };
      const modal = await modalController.create({
        component: SearchNotificationModal,
        componentProps: {
          title: 'Yeni Arama Bildirimi',
          searchNotification
        },
      });
      await modal.present();
      return this.notificationModal = modal;
    },
    editSearchNotification: async function (searchNotification) {
      const modal = await modalController.create({
        component: SearchNotificationModal,
        componentProps: {
          title: 'Arama Bildirimi Düzenleme',
          isNew: false,
          id: searchNotification.id,
          searchNotification
        },
      });
      await modal.present();
      return this.notificationModal = modal;
    },
    toggleSearchNotification: async function (notification) {
      let _this = this;
      this.$api.get('search-notifications/' + notification.id + '/toggle').then(() => {
        _this.$emitter.emit('updateProfile', _this.$api.getAuth());
      })
    },
    deleteSearchNotification: async function (notification) {
      let _this = this;
      this.$api.delete('search-notifications/' + notification.id).finally(() => {
        _this.$emitter.emit('updateProfile', _this.$api.getAuth());
      })
    },
    loadSeenNotifications: async function () {
      let response = await this.$api.Model.custom('notifications').page(this.pageNumber++).get();
      response.links.next === null ? this.nothingLeft = true : this.nothingLeft = false;
      this.notifications = this.notifications.concat(response.data);
    }
  },
  mounted() {
    let _this = this;
    this.$api.auth?.notifications?.length >= 10 ? this.nothingLeft = false : this.nothingLeft = true;
    this.$emitter.on('closeSearchNotificationModal', () => {
      _this.notificationModal.dismiss();
    });
    _this.$emitter.on('updateProfile', (res) => {
      res.then((profileData) => {
        _this.searchNotifications = profileData.searchNotifications;
        _this.notifications = profileData.notifications;
        _this.notificationOptions = profileData.notification_options;
      })
    });
    this.notifications = this.$api.auth?.notifications;
    this.notificationOptions = this.$api.auth?.notification_options;
    this.searchNotifications = this.$api.auth?.searchNotifications;
    this.$api.get('read-notifications').then(() => {
      _this.$emitter.emit('updateProfile', _this.$api.getAuth());
    })
  },
  data() {
    return {
      selectedTab: 'notifications',
      notificationModal: null,
      nothingLeft: true,
      pageNumber: 2,
      notifications: [],
      notificationOptions: [],
      searchNotifications: [],
    }
  }


});

</script>

<style scoped>
.red {
  color: red;
}

.green {
  color: green;
}

.tab-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: unset;
  z-index: 999;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
